import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./features/auth/ProtectedRoute";
import Search from "./features/search/Search";
import { I18nextProvider } from "react-i18next";
import i18n from "./translation";
import D4Weather from "./features/data4weather/D4Weather";
import Auth from "./features/auth/Auth";
import "./App.css";

export default function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Search />
              </ProtectedRoute>
            }
          />
          <Route
            path="/map"
            element={
              <ProtectedRoute>
                <D4Weather />
              </ProtectedRoute>
            }
          />
          <Route path="/auth/*" element={<Auth />} />
        </Routes>
      </Router>
    </I18nextProvider>
  );
}
