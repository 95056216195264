import { store } from "../../app/store";
import api from "./authApi";
import TokenService from "./tokenService";

const register = (username: string, email: string, password: string) => {
  return api.post("/auth/signup", {
    username,
    email,
    password,
  });
};

const login = (username: string, password: string) => {
  return api
    .post("/auth/signin", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.access_token) {
        TokenService.setUser(response.data);
      }

      return response.data;
    });
};

const logout = () => {
  TokenService.removeUser();
  localStorage.clear();
  store.dispatch({ type: "RESET_STATE" });
};

const getCurrentUser = () => {
  return TokenService.getUser();
};

const refreshToken = async (refreshToken: string) => {
  const response = await api.post("/auth/refresh-token", { refreshToken });
  return response.data;
};

const forgotPassword = async (email: string) => {
  return await api.post("/auth/forgot-password", { email });
};

const resetPassword = async (
  token: string,
  password: string,
  signout: boolean
) => {
  return await api.post(`/auth/reset-password/${token}`, {
    password,
    signout,
  });
};

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  forgotPassword,
  resetPassword,
  refreshToken,
};

export default AuthService;
