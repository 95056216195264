import React, { useState, useEffect, useRef } from 'react';
import OL, { Feature, Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS'
import OSM from 'ol/source/OSM';
import 'ol/ol.css';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import MVT from 'ol/format/MVT';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import Point from 'ol/geom/Point';
import {fromLonLat} from 'ol/proj';
import {Icon, Style} from 'ol/style.js';
import mapMarker from "../../assets/icons/svg/map-marker.svg";
import { useTranslation } from "react-i18next";
import HttpClient from "../auth/authApi";

function OLMap({style, selectedRisk, selectedRcp, center, zoom}: {style: any, selectedRisk: any, selectedRcp: any, center?: any, zoom?: any}) {
    const mapElement = useRef<HTMLDivElement | null>(null);
    const map = useRef<Map | null>(null);
    const dataLayer = useRef<any | null>(null);
    const markerLayer = useRef<any | null>(null);
    const osmLayer = useRef<any | null>(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (mapElement.current && !map.current) {
            const osmTileLayer = new TileLayer({
                preload: Infinity,
                className: 'ol_bw',
                source: new OSM(),
            })

            osmLayer.current = osmTileLayer

            const initialMap = new Map({
                target: mapElement.current || undefined,
                layers: [osmTileLayer],
                view: new View({
                    center: fromLonLat([2.333333, 48.866667]),
                    zoom: 10,
                    projection: "EPSG:4326"
                  }),
                pixelRatio: 1
              });
            map.current = initialMap

            initialMap.on('loadstart', function () {
                initialMap.getTargetElement().classList.add('spinner');
            });
            initialMap.on('loadend', function () {
                initialMap.getTargetElement().classList.remove('spinner');
            });
        }
    }, [mapElement]);

    type layerMapItem = {
        name: string,
        minZoom?: number,
        maxZoom?: number
    }

    const layerMap: Record<string, layerMapItem | null> = {
        fire: {name: "data4home:fire_map_fixed_with_city"},
        wind: {name: "data4home:wind_map"},
        drought: {name: "data4home:drought_high_res_map"},
        heavyRain: {name: "data4home:heavy-rain_map"},
        flood: {name: "data4home:flood_risk_area_view"}, //"data4home:floodriskarea",
        pluvialFlood: null, // "data4home:flood_risk_10yrs_paris_cleaned",
        hailStorm: {name: "data4home:hail_map"},
        submersion: {name: "data4home:marine_submersion", maxZoom: 11, minZoom: 6},
        coastalErosion: {name: "data4home:coastal_erosion", maxZoom: 11, minZoom: 6},
    }

    const rcpStyleMap: Record<string, string | null> = {
        rcp26: "multi_rcp_selection_26",
        rcp45: "multi_rcp_selection_45",
        rcp85: "multi_rcp_selection_85",
    }


    // deprecated from version 10.0.0
    // https://openlayers.org/en/latest/apidoc/module-ol_source_TileWMS-TileWMS.html#setTileLoadFunction
    // will need change when removed
    const tileLoader = async function(tile: any, src: string) {
        const response = await HttpClient.get(src, {responseType: 'arraybuffer'})
        const arrayBufferView = new Uint8Array(response.data);
        const blob = new Blob([arrayBufferView], { type: 'image/png' });
        const urlCreator = window.URL || (window as any).webkitURL;
        const imageUrl = urlCreator.createObjectURL(blob);
        tile.getImage().src = imageUrl;
      }

    useEffect(() => {
        if (!zoom && !center) { return }
        map.current?.setView(new View({
            center: fromLonLat([center.lng, center.lat]),
            zoom: zoom,
        }))

        const iconFeature = new Feature({
            geometry: new Point(fromLonLat([center.lng, center.lat])),
            name: 'Location',
        });

        const iconStyle = new Style({
            image: new Icon({
                anchor: [0.5, 1],
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                src: mapMarker,
            }),
        });

        iconFeature.setStyle(iconStyle);

        const vectorSource = new VectorSource({
            features: [iconFeature],
        });

        const vectorLayer = new VectorLayer({
            source: vectorSource,
        });

        markerLayer.current = vectorLayer
        map.current?.removeLayer(markerLayer.current)
        map.current?.addLayer(markerLayer.current)
    }, [center, zoom])

    useEffect(() => {
        if (!map.current) { return }

        map.current.removeLayer(dataLayer.current)

        if (!layerMap[selectedRisk])  { return }

        const geoServerLayerName = layerMap[selectedRisk]!.name
        const minZoom = layerMap[selectedRisk]?.minZoom || 0
        const maxZoom = layerMap[selectedRisk]?.maxZoom || 18

        let rcpStyle = ''
        if (["wind", "heavyRain"].includes(selectedRisk)) {
            rcpStyle = `data4home:${rcpStyleMap[selectedRcp]!}`
        }

        const layer = new TileLayer({
            source: new TileWMS({
                url: 'geoserver/wms',
                params: {'LAYERS': geoServerLayerName, 'TILED': true, 'STYLES': rcpStyle},
                serverType: 'geoserver',
                // Countries have transparency, so do not fade tiles:
                transition: 0,
                tileLoadFunction: tileLoader,
                projection: "EPSG:4326",
            }),
        })
        layer.setOpacity(0.6)
        dataLayer.current = layer
        // map.current.addLayer(layer)
        map.current.setLayers([osmLayer.current, dataLayer.current, markerLayer.current])
        map.current.getView().setMaxZoom(maxZoom)
        map.current.getView().setMinZoom(minZoom)

    }, [map.current, selectedRisk, selectedRcp])

    return (
        <div style={{position: "relative"}}>
            <div style={{...style}} ref={mapElement} className="map-container">
            </div>
            { selectedRisk && layerMap[selectedRisk] && (!!layerMap[selectedRisk]?.minZoom || !!layerMap[selectedRisk]?.maxZoom) &&
                <div style={{position: "absolute", left: "0", bottom: "0", padding: "0 10px 0 10px", display: "flex", flexDirection: "column", alignContent: "center", justifyContent: "center", backgroundColor: "rgba(0,0,0,0.4)"}}>
                    <span style={{flex: 0, fontSize: "1em", color: "white", textAlign: "center", fontWeight: "bold"}}>{t("map.limitedZoomLevelMessage")}</span>
                </div>
            }
            { selectedRisk && layerMap[selectedRisk] == null &&
                <div style={{position: "absolute", top: "0", left: "0", bottom: "0", right: "0", display: "flex", flexDirection: "column", alignContent: "center", justifyContent: "center", backgroundColor: "rgba(0,0,0,0.6)"}}>
                    <span style={{flex: 0, fontSize: "2em", color: "rgb(244, 82, 77)", textAlign: "center", fontWeight: "bold"}}>{t("map.underConstruction")}</span>
                </div>
            }
        </div>
    );
}

export default OLMap;