import MapSearchResultBlock from "./MapSearchResultBlock";
import BusinessSearchResultBlock from "./BusinessSearchResultBlock";
import SatelliteSearchResultBlock from "./SatelliteSearchResultBlock";
import StreetViewSearchResultBlock from "./StreetViewSearchResultBlock";
import WeatherSearchResultBlock from "./WeatherSearchResultBlock";
import ClimateRiskResultBlock from "./ClimateRiskResultBlock";
import { BlockItemType } from "../../types";
import CatnatRiskListBlock from "./CatnatRiskListBlock";
import PerilRiskListBlock from "./PerilRiskListBlock";
import SiteSevesoListBlock from "./SiteSevesoListBlock";
interface SearchBlocksWrapperType {
  blockItemList: BlockItemType[];
}

export default function SearchBlocksWrapper({
  blockItemList,
}: SearchBlocksWrapperType): JSX.Element {
  const returnSearchBlocks = () => {
    return blockItemList.map((item: BlockItemType, index: number) => {
      const searchBlocks: Record<string, JSX.Element> = {
        map_search: (
          <MapSearchResultBlock key={`search_block_${index}`} {...item} />
        ),

        business_search: (
          <BusinessSearchResultBlock key={`search_block_${index}`} {...item} />
        ),

        satellite_image_search: (
          <SatelliteSearchResultBlock key={`search_block_${index}`} {...item} />
        ),

        streetview_image_search: (
          <StreetViewSearchResultBlock
            key={`search_block_${index}`}
            {...item}
          />
        ),

        weather_search: (
          <WeatherSearchResultBlock key={`search_block_${index}`} {...item} />
        ),
        climate_risk: (
          <ClimateRiskResultBlock
            key={`climate_risk_block_${index}`}
            {...item}
          />
        ),
        catnat_risk: (
          <CatnatRiskListBlock key={`catnat_block_${index}`} {...item} />
        ),
        peril_risk: (
          <PerilRiskListBlock key={`catnat_block_${index}`} {...item} />
        ),
        site_seveso: (
          <SiteSevesoListBlock key={`catnat_block_${index}`} {...item} />
        ),
      };
      return searchBlocks[item.id];
    });
  };

  return <>{returnSearchBlocks()}</>;
}
